.il-dark {
  @import '_il-dark';
  @import '~bootstrap/scss/bootstrap.scss';
}
.il-light {
  @import '_il-light';
  @import '~bootstrap/scss/bootstrap.scss';
}

.text-pre {
  white-space: pre;
}

.navbar-nav .text-topmenu {
  color: #adb5bd !important;
}

.text-topmenu {
  color: #002349 !important;
}

@import 'prynet-app';
