html {
  min-height: 100vh;
}
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

body.modal-open {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer !important;
}

.bg-topmenu {
  background-image: url("/assets/background-topmenu.png");
  background-position: 100vw;
  background-size: cover;
}

.bg-login {
  background-image: url("/assets/background-login.png");
  background-position: 100vw;
  background-size: cover;
  @media (max-width: 768px) {
    background-image: url("/assets/background-login-mobile.png");
  }
}

.il-light .bg-danger a.text-secondary {
  color: #444 !important;
}

.il-dark .breadcrumb-item.active {
  color: white;
}

.adapt-mobile {
  .hide-on-mobile {
    @media (max-width: 767px) {
      display: none
    }
  }
}
